@import '../node_modules/bootstrap/scss/bootstrap';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.main-content {
  max-height: calc(100vh - 3.5rem - 1px);
  position: relative;
  overflow-y: auto;
}

.centered-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

//
///* Small */
//@media (min-width: 576px) {
//  .test-class {
//    max-width: 90vw;
//    //max-height: 90vh;
//  }
////  .inner-page {
////    width: 100vw;
////  }
////  .test-class {
////    padding-bottom: 0;
////  }
//}
//
///* Medium */
//@media (min-width: 768px) {
//  .inner-page {
//    width: 70rem;
//  }
//}

.livecam-thumbnail {
  width: 100%;
  cursor: pointer;
}


//.loader,
//.loader:after {
//  border-radius: 50%;
//  width: 10em;
//  height: 10em;
//}
//.loader {
//  margin: 60px auto;
//  font-size: 10px;
//  position: relative;
//  text-indent: -9999em;
//  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
//  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
//  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
//  border-left: 1.1em solid #ffffff;
//  -webkit-transform: translateZ(0);
//  -ms-transform: translateZ(0);
//  transform: translateZ(0);
//  -webkit-animation: load8 1.1s infinite linear;
//  animation: load8 1.1s infinite linear;
//}
//@-webkit-keyframes load8 {
//  0% {
//    -webkit-transform: rotate(0deg);
//    transform: rotate(0deg);
//  }
//  100% {
//    -webkit-transform: rotate(360deg);
//    transform: rotate(360deg);
//  }
//}
//@keyframes load8 {
//  0% {
//    -webkit-transform: rotate(0deg);
//    transform: rotate(0deg);
//  }
//  100% {
//    -webkit-transform: rotate(360deg);
//    transform: rotate(360deg);
//  }
//}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dropdown-item:active {
  background-color: inherit;
  outline: none;
}